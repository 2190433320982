'use strict';

(function (namespace) {

  let languageSwitch = namespace.languageSwitch;

  if (languageSwitch) {
    // already initialized, ignore
    return;
  }

  /**
   * Selector
   *
   * @private
   * @readonly
   */
  const CLASSES = {
    linkList: 'cc-navigation__language-switch',
    linkItem: 'cc-navigation__language-switch--link',
    activeLinkItem: 'cc-navigation__language-switch--active'
  };

  /**
   * Selector
   *
   * @private
   * @readonly
   */
  const SELECTORS = {
    links: `.${CLASSES.linkList} .${CLASSES.linkItem}`
  };

  function handleLanguageClick(event) {
    event.preventDefault();
    const clickedLang = event.target.getAttribute('href');
    const activeHref = window.location.href;
    const activeLang = window.location.href.includes('/de/') ? 'de' : 'en';
    if (activeLang !== clickedLang) {
      if (activeLang === 'de' && clickedLang === 'en') {
        window.location.href = activeHref.replace('/de/', '/en/');
      } else if (activeLang === 'en' && clickedLang === 'de') {
        window.location.href = activeHref.replace('/en/', '/de/');
      }
    }
  }

  /**
   * The controller of this module.
   *
   * @class
   * @this { object }
   */
  const controller = new class LanguageSwitchController {
    init() {
      const linkItems = document.querySelectorAll(SELECTORS.links);
      for (let i = 0; i < linkItems.length; i++) {
        linkItems[i].addEventListener('click', handleLanguageClick);
      };
    }
  }();

  languageSwitch = {
    /**
     * Initializes.
     *
     * @returns {} - Returns nothing.
     * @public
     */
    init: function() {
      controller.init();
    },
  };

  namespace.languageSwitch = languageSwitch;

  // Initialization
  // =========================================================================

  document.addEventListener('DOMContentLoaded', function() {
    languageSwitch.init();
  });

}(
  window.coco || (window.coco = {})
));
