'use strict';

(function (namespace) {

  let navigation = namespace.navigation;

  if (navigation) {
    // already initialized, ignore
    return;
  }

  const CLASSES = {
    mobileToggle: 'cc-navigation__toggle',
    navigation: 'cc-navigation',
    navigationContainerInner: 'cc-navigation__container--inner',
    pageFrameMobile: 'cc-page-frame__mobile',
    open: 'open',
    navigationList: 'cc-navigation__list',
    navigationListLink: 'cc-navigation__list--item-link',
    header: 'cc-header'
  };

  /**
   * Selector
   *
   * @type {{
    *  body: string
    * }}
    * @private
    * @readonly
    */
   const SELECTORS = {
     mobileToggle: `.${CLASSES.mobileToggle}`,
     navigation: `.${CLASSES.navigation}`,
     navigationContainerInner: `.${CLASSES.navigationContainerInner}`,
     pageFrameMobile: `.${CLASSES.pageFrameMobile}`,
     navigationLink: `.${CLASSES.navigationList} .${CLASSES.navigationListLink}`,
     header: `.${CLASSES.header}`
   };

  /**
   * The controller of this module.
   *
   * @class
   * @this { object }
   */
  const controller = new class NavigationController {
    addNavigationEventListener() {
      const mobileToggleElement = document.querySelector(SELECTORS.mobileToggle);
      mobileToggleElement.addEventListener('click', this.onClickMobileMenu, false);

      const navigationLinks = document.querySelectorAll(SELECTORS.navigationLink);
      for (let i = 0; i < navigationLinks.length; i++) {
        navigationLinks[i].addEventListener('click', this.onClickNavigation.bind(this), false);
      };
    }

    onClickMobileMenu() {
      const navigationContainerInnerElement = document.querySelector(SELECTORS.navigationContainerInner);
      navigationContainerInnerElement.classList.toggle(CLASSES.open);

      const pageFrameMobileElement = document.querySelector(SELECTORS.pageFrameMobile);
      pageFrameMobileElement.classList.toggle(CLASSES.open);
    }

    checkUrlHash() {
      const hash = window.location.hash;
      setTimeout(() => {
        this.setScrollPosition(hash.replace('#', ''));
      }, 50);
    }

    setScrollPosition(linkId) {
      if (linkId) {
        const targetPositionOnPage = document.querySelector('#' + linkId).getBoundingClientRect().top;

        const body = document.body;
        const docEl = document.documentElement;
        const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        const headerHeight = document.querySelector(SELECTORS.header).getBoundingClientRect().height;

        window.scroll({
          top: targetPositionOnPage + scrollTop - headerHeight,
          behavior: 'smooth'
        });
      }
    }

    onClickNavigation(event) {
      const linkId = event.target.getAttribute('href').split('#')[1];
      // do smooth scroll if ids are on page
      if (document.querySelector('#' + linkId)) {
        event.preventDefault();
        this.setScrollPosition(linkId);
      }
      this.onClickMobileMenu();
    }
  }();

  navigation = {
    /**
     * Initializes.
     *
     * @returns {} - Returns nothing.
     * @public
     */
    init: function() {
      controller.addNavigationEventListener();
      controller.checkUrlHash();
    },
  };

  namespace.navigation = navigation;

  // Initialization
  // =========================================================================

  document.addEventListener('DOMContentLoaded', function() {
    navigation.init();
  });

}(
  window.coco || (window.coco = {})
));
