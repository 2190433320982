'use strict';

(function (namespace) {

  let scroll = namespace.scroll;

  if (scroll) {
    // already initialized, ignore
    return;
  }

  const CLASSES = {
    scrolled: 'scrolled'
  };

  /**
   * Selector
   *
   * @type {{
    *  body: string
    * }}
    * @private
    * @readonly
    */
   const SELECTORS = {
     body: 'body',
     header: '.cc-header'
   };

  /**
   * The controller of this module.
   *
   * @class
   * @this { object }
   */
  const controller = new class ScrollController {
    addScrollEventListener() {
      window.addEventListener('scroll', this.onScroll, false);
    }

    onScroll() {
      const bodyElement = document.querySelector(SELECTORS.body);
      const headerElement = document.querySelector(SELECTORS.header);
      const headerHeight = headerElement.offsetHeight;

      if (window.scrollY > headerHeight) {
        bodyElement.classList.add(CLASSES.scrolled);
      } else {
        bodyElement.classList.remove(CLASSES.scrolled);
      }
    }
  }();

  scroll = {
    /**
     * Initializes.
     *
     * @returns {} - Returns nothing.
     * @public
     */
    init: function() {
      controller.addScrollEventListener();
    },
  };

  namespace.scroll = scroll;

  // Initialization
  // =========================================================================

  document.addEventListener('DOMContentLoaded', function() {
    scroll.init();
  });

}(
  window.coco || (window.coco = {})
));
