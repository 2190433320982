'use strict';

(function (namespace) {

  let slider = namespace.slider;

  if (slider) {
    // already initialized, ignore
    return;
  }

  /**
   * Selector
   *
   * @private
   * @readonly
   */
   const SELECTORS = {
     slider: '.cc-slider__content'
   };

  /**
   * The controller of this module.
   *
   * @class
   * @this { object }
   */
  const controller = new class SliderController {
    init() {
      $(SELECTORS.slider).slick({
        autoplay: true,
        prevArrow: '.cc-slider__prev',
        nextArrow: '.cc-slider__next'
      });
    }
  }();

  slider = {
    /**
     * Initializes.
     *
     * @returns {} - Returns nothing.
     * @public
     */
    init: function() {
      controller.init();
    },
  };

  namespace.slider = slider;

  // Initialization
  // =========================================================================

  document.addEventListener('DOMContentLoaded', function() {
    slider.init();
  });

}(
  window.coco || (window.coco = {})
));
