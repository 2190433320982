'use strict';

(function (namespace) {

  let lazyload = namespace.lazyload;

  if (lazyload) {
    // already initialized, ignore
    return;
  }

  const CLASSES = {
    lazyload: 'lazyload'
  };

  const SELECTORS = {
    lazyload: `.${CLASSES.lazyload}`
  };

  lazyload = {
    /**
     * Initializes.
     *
     * @returns {} - Returns nothing.
     * @public
     */
    init: function() {
      const lazyLoadInstance = new LazyLoad({
        elements_selector: SELECTORS.lazyload,
        load_delay: 300 //adjust according to use case
      });
    },
  };

  namespace.lazyload = lazyload;

  // Initialization
  // =========================================================================

  document.addEventListener('DOMContentLoaded', function() {
    lazyload.init();
  });

}(
  window.coco || (window.coco = {})
));
